import { render } from "./FillData.vue?vue&type=template&id=19aef5a5"
import script from "./FillData.ts?vue&type=script&lang=ts"
export * from "./FillData.ts?vue&type=script&lang=ts"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "19aef5a5"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('19aef5a5', script)) {
    api.reload('19aef5a5', script)
  }
  
  module.hot.accept("./FillData.vue?vue&type=template&id=19aef5a5", () => {
    api.rerender('19aef5a5', render)
  })

}

script.__file = "src/views/FillData/FillData.vue"

export default script