import { render } from "./ListLoader.vue?vue&type=template&id=61034d6f"
import script from "./ListLoader.ts?vue&type=script&lang=ts"
export * from "./ListLoader.ts?vue&type=script&lang=ts"

import "./listLoader.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "61034d6f"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('61034d6f', script)) {
    api.reload('61034d6f', script)
  }
  
  module.hot.accept("./ListLoader.vue?vue&type=template&id=61034d6f", () => {
    api.rerender('61034d6f', render)
  })

}

script.__file = "src/components/ListLoader/ListLoader.vue"

export default script