import { render } from "./Signup.vue?vue&type=template&id=82e4219a"
import script from "./Signup.ts?vue&type=script&lang=ts"
export * from "./Signup.ts?vue&type=script&lang=ts"

import "./signup.scss?vue&type=style&index=0&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "82e4219a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('82e4219a', script)) {
    api.reload('82e4219a', script)
  }
  
  module.hot.accept("./Signup.vue?vue&type=template&id=82e4219a", () => {
    api.rerender('82e4219a', render)
  })

}

script.__file = "src/views/Singup/Signup.vue"

export default script